<template>
	<div class="padding-top-10 contain_pay">
		<top :bgWhite='true'></top>
		<div class="content_pay bg-white ">    
            <div class="headbox">
                <img src="../../assets/image/paysucsess.png" class="block-54" alt="">
                <div class="margin-t-20">订单支付成功!</div>
                <div class="paytype">
                    <span>支付金额:￥{{real_money}}</span>
                    <span style="margin-left:73px">支付方式:{{ pay_type}}</span>
                </div>
                <div class="codebox">
                    <img :src="serve_img" class="block-96" style="margin:22px auto" alt="">
                    <div style="color:#666；font-size:14px">下单成功后，请保存二维码联系平台客服</div>
                    <div class="margin-t-20">
                        <el-botton class="preser" @click="downimg">保存</el-botton>
                    </div>
                </div>
                </div>
                <div class="footer">
                        <el-botton class="ckdd" @click="toorder">查看订单</el-botton>
                        <el-botton class="backsy" @click="toindex">返回首页</el-botton>
                    </div> 
		</div>
        <bottom ></bottom>
	</div>
</template>

<script>
	import payCode from  '@/components/pay-code/pay-code.vue'
	import top from '@/components/top'
	import bottom from '@/components/bottom'
import catalogVue from '../fiction/catalog.vue'
	export default {
		name: 'authen',
		components: {
			bottom,
			top,
			payCode
		},
		data() {
			return {
				url:'', //
				timer: null,
				qrcode:'', //微信付二维码
				qrcodeVisible:false, //二维码显隐
				dialogVisible:false,
				currentIndex: 0, //选择的索引
				dialogTableVisible: false, //是否展示协议
				order_money: '0.00', //价格
				body: '', //会员权益
                isconfirm:0 , // 是否从拼团跳转
                serve_img:'', // 客服二维码
                pay_type:'', // 支付方式
                pay_time:'',  //支付时间
                real_money:'',
                 order_sn: '',
				navList: [{
					id: 1,
					name: '余额支付',
					payType: 'balance',
					img: require('../../assets/image/balance.png'),
				},{
					id: 2,
					name: '微信支付',
					payType: 'wechat',
					img: require('../../assets/image/wechat.png'),
				}, {
					id: 3,
					name: '支付宝支付',
					payType: 'zfb',
					img: require('../../assets/image/zfb.png')
				}]
			}
		},
		mounted() {
			this.order_sn = this.$route.query.order_sn;
            this.getServiceQrcode()
            this.getOrderInfo()
		},
		methods: {
			// 输入密码完成
			inputSuccess(e){
				console.log(e);
				let password = e;
				// 余额支付
				this.paymentByBalance(e)
			},
			// 选择支付方式
			tabOne(item, index) {
				this.currentIndex = index
			},
			// 协议弹框
			toTermsPage() {
				this.dialogTableVisible = true
			},
			// 立即支付
			submitForm(val) {
				if(this.currentIndex==0){
					// 余额支付
					this.paymentByBalanceIsSetPass();
				}else if(this.currentIndex==1){
					// 微信支付
					this.paymentWechat()
				}else if(this.currentIndex==2){
					let return_url = this.$route.query.return_url;
					console.log(`https://www.xcrck.com/index/pay/h5alipay?order_sn=${this.order_sn}&return_url=${return_url}`);
					// 支付宝支付
					window.location.href = `https://www.xcrck.com/index/pay/h5alipay?order_sn=${this.order_sn}&return_url=${return_url}`
				}
			},
			close(val){
				this.dialogVisible = false;
			},
			/**
			 * @function
			 * @description 余额支付判断是否设置了支付密码
			 */
			paymentByBalanceIsSetPass() {
			  this.ajax('post','/v1/5f64a4d364b44', {
			    pay_money: this.order_money
			  },res => {
			    if (res.code == 1) {
			      if (res.data.falg == 1) {
			         this.dialogVisible = true
			      } else {
			        this.$message.info(res.msg);
			        this.$router.push({
						path:'/setting',
						query:{
							type:1
						}
					})
			      }
			    } else {
			      this.$message.info(res.data.msg);
			    }
			  }).catch(err => {
			    this.loading = false;
			  })
			},
			/**
			 * @function
			 * @description 余额支付
			 */
			paymentByBalance(password){
				this.ajax('post','/v1/5e489f45a051d', {
				  order_sn: this.order_sn,
				  pay_password:password
				},res => {
					console.log(res);
				  if (res.code == 1) {
					this.$message.success(res.msg)
					if(this.isconfirm == 1){

					}else{
						this.$router.go(-1)
					}
				  } else {
					this.dialogVisible = false;
					this.$refs.paycode.clearPsw();
				    this.$message.error(res.msg)
				  }
				}).catch(err => {
				  this.loading = false;
				})
			},
			/**
			 * @function
			 * @description 微信支付
			 */
			paymentWechat(){
				this.ajax('post','/v1/61a19e332e39e', {
				  order_sn: this.order_sn
				},res => {
				  if (res.code == 1) {
					 // 轮询
					 if(res.data){
						this.qrcode = res.data;
						this.qrcodeVisible = true;
						if (!this.timer) {
							this.timer = setInterval(() => {
								this.payStatusPoll()
							}, 1000) 
						}
					 }else{
						 this.$message.error(res.msg)
					 }
				  } else {
					this.$message.error(res.msg)
				  }
				}).catch(err => {
				  this.$message.error('服务器错误，请重新')
				})
			},
			/**
			 * @function
			 * @description 获取下单成功的客服二维码
			 */
			getServiceQrcode(){
				this.ajax('post','/v1/638aaa690025b', {
				  code: 'scrabble.service_img'
				},res => {
				  if (res.code == 1) {
              this.serve_img = res.data
            } else {}
				}).catch(err => {
				  clearInterval(this.timer);
				  this.timer = null;
				  this.$message.error('服务器错误，请重新')
				})
            },
            /**
			 * @function
			 * @description 获取支付方式，支付时间
			 */
			getOrderInfo(){
				this.ajax('post','/v1/616790445e001', {
				  order_sn: this.order_sn
				},res => {
				  if (res.code == 1) {
              this.pay_type = res.data.pay_type;
              this.pay_time = res.data.pay_time;
              this.real_money = res.data.real_money;
            } else {}
				}).catch(err => {
				  clearInterval(this.timer);
				  this.timer = null;
				  this.$message.error('服务器错误，请重新')
				})
            },
            //保存图片
            downimg(){
                window.open(this.serve_img)
            },
            toorder(){
                this.$router.push({
						path:'/order-list',
						query:{
							type:1
						}
					})
            },
            toindex(){
                this.$router.push({
						path:'/spell-book'
					})
            }   
		}
	}
</script>

<style scoped>
	.contain_pay {
		width: 100vw;
		height: 100vh;
		background-color: #F8F8F8;
		overflow: auto;
	}

	.content_pay {
		width: 80%;
		height: auto;
		margin: 80px auto;
        border: 1px solid #eee;
        padding: 72px 0;
	}
    .headbox{
        text-align: center;
    }
    .margin-t-20{
        margin-top: 20px;
    }
    .paytype{
        color: #999;
        margin-top: 30px;
    }
    .codebox{
        width: 324px;
        height: 228px;
        padding: 22px;
        background: rgba(229,79,66,0.08);
        border: 1px solid #E54F42;
        margin: 32px auto;
    }
    .preser{
        background: #E54F42;
        color: #fff;
        border-radius: 44px;
        padding: 5px 25px;
    }
	.avatar {
		width: 200px;
		height: 126px;
		display: block;
	}
    .footer{
        margin-top: 56px;
        text-align: center;
    }
    .ckdd{
        padding: 10px 58px;
        background: rgba(229,79,66,0.08);
        border: 1px solid #E54F42;
        color: #E54F42;
        margin-right: 20%;
        border-radius: 64px;
        }
        .backsy{
        padding: 10px 58px;
        background: #E54F42;
        color: #fff;
        border-radius: 64px;
        }
	/deep/.el-input {
		width: 320px;
		height: 36px;
	}
</style>
